import React from 'react';
import hp from '../../images/vandors/hp-min.png';
import fujitus from '../../images/vandors/Fujitsu-min.png';
import microsoft from '../../images/vandors/Microsoft-min.png';
import juniper from '../../images/vandors/Juniper-min.png';
import ibm from '../../images/vandors/ibm-min.png';
import emc from '../../images/vandors/emc-min.png';
import dell from '../../images/vandors/dell-min.png';
import cisco from '../../images/vandors/cisco-min-1.png';


const VandorSupport = () => {
    return (
        <div className="flex gap-10 flex-wrap items-center justify-center lg:mx-60 lg:px-20">
            <div className="w-56 h-28 shadow-lg rounded-xl flex justify-center items-center ">
                <img className="p-20 " alt="" src={hp} />
            </div>
            <div className="w-56 h-28 shadow-lg rounded-xl flex justify-center items-center">
                <img className="p-16" alt="" src={fujitus} />
            </div>
            <div className="w-56 h-28 shadow-lg rounded-xl flex justify-center items-center">
                <img className="p-10 " alt="" src={microsoft} />
            </div>
            <div className="w-56 h-28 shadow-lg rounded-xl flex justify-center items-center">
                <img className="p-12 " alt="" src={juniper} />
            </div>
            <div className="w-56 h-28 shadow-lg rounded-xl flex justify-center items-center">
                <img className="p-16 " alt="" src={cisco} />
            </div>
            <div className="w-56 h-28 shadow-lg rounded-xl flex justify-center items-center">
                <img className="p-16 " alt="" src={ibm} />
            </div>
            <div className="w-56 h-28 shadow-lg rounded-xl flex justify-center items-center">
                <img className="p-16 " alt="" src={emc} />
            </div>
            <div className="w-56 h-28 shadow-lg rounded-xl flex justify-center items-center">
                <img className="p-20 " alt="" src={dell} />
            </div>
        </div>
    );
};

export default VandorSupport;