import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { FaLandmark, FaLocationArrow, FaPhone, FaVoicemail } from 'react-icons/fa';
const ContactMe = () => {
    const form = useRef();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // handle form submission here
        alert(`Thank you ${name} for reaching out! We will get back to you soon`);
        setName('');
        setEmail('');
        setMessage('');


        emailjs.sendForm('service_k5it2vi', 'template_cdvu056', form.current, '_X-9nk7MAZbMCF6Fr')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div>
            <div className="lg:w-1/2 lg:mx-auto mx-5 text-center">
                <h3 className="uppercase font-bold text-center text-5xl">Request for a service</h3>
                <p className=" text-2xl my-4 text-[#e81c2e] font-bold">Please fill out the form on this section to request a service. Or call between 9:00 a.m. and 8:00 p.m. ET, Monday through Friday </p>

            </div>
            <div className="lg:flex mx-5 justify-space lg:mx-20 my-20">
                <div className="grid gap-10 w-1/2 mb-10">
                    <div className="flex gap-10 items-center">
                        <div className="p-5">
                            <FaLocationArrow className="w-8 h-8 text-[#e81c2e]"></FaLocationArrow>
                        </div>

                        <div className="grid">
                            <h1 className="text-xl font-bold text-red my-5">Address</h1>
                            <p className="">71-75 Shelton Street,<br /> Covent Garden, London, WC2H9JQ</p>
                        </div>
                    </div>
                    <div className="flex gap-10 items-center">
                        <div className="p-5">
                            <FaVoicemail className="w-8 h-8 text-[#e81c2e]"></FaVoicemail>
                        </div>

                        <div className="grid">
                            <h1 className="text-xl font-bold text-red my-5">Email</h1>
                            <p className=""><a href="mailto:info@adiyaat.com">info@adiyaat.com</a></p>
                        </div>
                    </div>
                    <div className="flex gap-10 items-center">
                        <div className="p-5">
                            <FaPhone className="w-8 h-8 text-[#e81c2e]"></FaPhone>
                        </div>

                        <div className="grid">
                            <h1 className="text-xl font-bold text-red my-5">Phone</h1>
                            <p className=""><a href="tel:+447477194822">+447477194822</a></p>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <label>
                        Name:
                        <input type="text" value={name} onChange={handleNameChange} />
                    </label>
                    <br />
                    <label>
                        Email:
                        <input type="email" value={email} onChange={handleEmailChange} />
                    </label>
                    <br />
                    <label>
                        Message:
                        <textarea value={message} onChange={handleMessageChange} />
                    </label>
                    <br />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default ContactMe;