import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/Group 2logo-1.png';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

const Footer = () => {
    const date = new Date();
    const [services, setService] = useState([]);

    useEffect(() => {
        fetch("4services.json")
            .then(res => res.json())
            .then(data => setService(data))
    }, []);
    return (
        <footer className="static bottom-0 bg-[#f1eeee] w-full" >
            <div className="inline-grid p-20 md:flex md:justify-evenly py-16  gap-5 ">
                <div className="text-start w-96 mt-16">
                    <img className="w-2/3" alt="" src={logo} />
                    <p className="text-gray-800 text-lg">Adiyaat It Solution Ltd. is a reputable and dependable provider of IT solutions and infrastructure services. We take great pride in delivering top-notch IT solutions to our valued clients with a strong commitment to quality and customer satisfaction.</p>
                </div>
                <div className="w-16"></div>
                <div className="text-start pt-16">
                    <h1 className="text-2xl font-bold mb-5 uppercase text-rose-600">Links</h1>
                    <ul className="inline-grid text-gray-800 text-lg font-semibold">
                        <Link to="/">About us</Link>
                        <Link to="/">Services</Link>
                        {/* <Link to="/">Blog</Link> */}
                        <Link to="/blog">Contact us</Link>
                    </ul>
                </div>
                <div className="text-start pt-16">
                    <h1 className="text-2xl font-bold mb-5 uppercase text-rose-600"> Services</h1>
                    <ul className="inline-grid text-gray-800 text-lg font-semibold">
                        {services.map(service => <Link key={service.no} to="/services">{service.name}</Link>)}

                    </ul>
                </div>
                <div id='contact' className="text-start pt-16">
                    <h1 className="text-2xl font-bold mb-5 uppercase text-rose-600">Get in touch</h1>
                    <div className="inline-grid text-gray-800 text-lg">
                        <div className='flex items-center gap-2'> <FaEnvelope /><a href="mailto:info@adiyaat.com">info@adiyaat.com</a></div>
                        <div className='flex items-center gap-2 mt-2'> <FaPhone /> <a href="tel:+447477194822">+447477194822</a> </div>
                        <div className="text-xl font-semibold mt-5">Address </div>
                        <p>71-75 Shelton Street,<br/> Covent Garden, London, WC2H9JQ</p>
                    </div>
                    <ul className="flex gap-5">
                        {/* <Link to="/services"><FaGoogle></FaGoogle></Link>
                        <Link to="/services"><FaLinkedin></FaLinkedin></Link>
                        <Link to="/services"><FaFacebook></FaFacebook></Link>
                        <Link to="/services"><FaGoogle></FaGoogle></Link> */}

                    </ul>
                </div>
            </div>
            <p className="text-center text-gray-500 text-xs pb-4">
                &copy;{date.getFullYear()} Adiyaat IT Solution Ltd. All rights reserved.
            </p>
        </footer>
    );
};

export default Footer;