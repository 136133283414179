import React from 'react';

const SingleService = ({ service }) => {
    const img = service.img;
    return (
        <div>
           
            <div className='rounded p-8 bg-[#f36873] hover:bg-[#f32839] w-[390px] h-[500px]'>
                {/* <p className="text-slate-50 font-semibold">{service.no}</p> */}
                <img className="w-16 py-5" alt="" src={img} />
                <h1 className="text-xl uppercase font-bold my-5 text-slate-50">{service.name}</h1>
                <div className="my-5">
                {
                    service.feature.map(feature => <li key={service.no} className="text-slate-100 text-lg list-disc list-inside">{feature}</li>)
                }
                </div>
            </div>
        </div>
    );
};

export default SingleService;