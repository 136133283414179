import React, { useEffect, useState } from 'react';
import SingleService from './SingleService';

const Services = () => {
    const [services, setService] = useState([]);

    useEffect(() => {
        fetch("4services.json")
            .then(res => res.json())
            .then(data => setService(data))
    }, []);

   
    return (
        <div className="flex flex-wrap gap-10 justify-center my-20">
            
            {
                services.map(service => <SingleService
                    key={service.no}
                    service={service}
                ></SingleService>)
            }
           
        </div>
    );
};

export default Services;