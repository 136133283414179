import React from 'react';
import { FaFacebook, FaLinkedin, FaLinkedinIn } from 'react-icons/fa';
import logo from '../../images/Group 2logo-1.png'

const GetaQuery = () => {
    return (
        <div className="bg-slate-100">
            
            <div className="h-60 flex justify-center items-end bg-[#e96565]"><h3 className="pb-5 text-xl font-bold text-slate-50 flex items-center gap-2">Visit Our Social Medias:  <a href="https://www.linkedin.com/company/adiyaat-it-solution/" target="_blank"> <FaLinkedinIn className="text-[#0a66c2] bg-slate-50 p-[2px]"/> </a></h3></div>
            <div className="lg:w-2/4 h-60 bg-white flex items-center relative bottom-80 lg:left-1/4 rounded-xl">
                <div className='absolute m-5'>
                    <h3 className="text-rose-600 lg:text-2xl font-bold mb-10 overflow-hidden">Get A Free Quote Or Contact Us For Any Query</h3>
                    <div className='flex items-center gap-10 mt-10'>
                        <a className='font-bold uppercase bg-red-600 text-white p-3 my-4 rounded-xl' href='#contact'>Contact us</a>
                        <p className="text-rose-700 text-xl font-bold">OR</p>
                        <div>
                            <h6 className="text-[#e96565] font-semibold uppercase"> Call us</h6>
                            <h6 className="text-[#0c0c0c] font-semibold"><a href="tel:+447477194822">+447477194822</a> </h6>

                        </div>
                    </div>
                </div>
                <div className='absolute right-1'>
                    <img className='w-40 hidden lg:block md:block' alt="" src={logo} />
                </div>
            </div>
        </div>
    );
};

export default GetaQuery;