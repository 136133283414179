import image from '../../images/datacenterpage1.png';
// import cisco from '../../images/1280px-Cisco_logo.svg.png';ee
// import breakFix from '../../images/computer-service-svgrepo-com.svg';
import './Home.css';
import Services from '../Services';
// import dataCenter from '../../images/data_center_maintainance_white.png';
import VandorSupport from './VandorSupport';
import GetaQuery from './GetaQuery';
import aboutus from '../../images/aboutus.png';
import { Link } from 'react-router-dom';

const Home = () => {



    return (
        <div className="min-w-full">
            <div className="lg:my-40 lg:flex lg:justify-between lg:items-center gap-10">
                <img className="order-2 lg:w-2/3 ml-5 " src={image} alt="mainpic" />
                <div className="order-1 pl-5 font-familly">
                    <h1 className="text-3xl font-bold main-title uppercase tracking-wide">Technology & innovation boost business progress</h1>
                    {/* <h3 className="text-3xl font-bold texciscot-slate-600">We Care Your any IT Solution</h3> */}
                    {/* <p className="text-xl my-8 text-black">Your company adopts, develops, and innovates on the basis of a core IT system that is successfully managed</p> */}
                    {/* <div className="flex gap-2 sm:items-start">
                        <Link to="reqForService"><button className="btn bg-[#e81c2e] border-[#e81c2e] rounded text-white ">Request For Support</button></Link>

                        <button className="btn ml-8 px-5 bg-white border-[#131212] rounded hover:text-white text-[#e81c2e]"><a href="#contact">Contact Us</a></button>

                    </div> */}
                </div>


            </div>


            <svg className="animate-bounce w-8 h-8 mx-auto border border-black rounded-full p-1 font-bold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
            </svg>


            {/* About us */}
            <section className="my-16" id='aboutus'>
                <header className="py-5">
                    <h5 className="uppercase font-bold text-center text-primary text-sm">Every aspect of AROLID IT</h5>
                    <h2 className="uppercase font-bold text-center text-5xl">About us</h2>
                </header>

                <div className="mt-10 mx-8 md:flex justify-center items-center gap-20">
                    <div className="w-full lg:w-1/3">

                        <h2 className="mb-12 uppercase text-4xl lg:text-6xl text-[#e81c2e] font-bold">WHAT DRIVES US move forward</h2>
                        <p className="text-lg"> We are a dynamic and innovative
                            IT solutions company based in the United Kingdom. Our team of experienced
                            professionals is dedicated to providing cutting-edge technology and unparalleled
                            service to our clients. We specialize in delivering customized solutions to meet
                            the unique needs of businesses of all sizes, across various industries.
                            Our range of services includes IT and Desktop support, Network support, IMAC,
                            Hardware Break fix, data center maintenance, Site survey,  web development,
                            software development, and much more. We are proud to
                            be a UK government registered company and are committed to maintaining
                            the highest level of compliance and security standards. Our goal is
                            to help our clients achieve their goals and succeed in today's
                            digital age. With our knowledge, expertise, and passion for technology,
                            we are confident in our ability to provide the solutions and support
                            that will drive your business forward.
                        </p>
                    </div>
                    <div className="lg:w-1/3 mt-10">
                        <img className="" alt="" src={aboutus} />
                    </div>
                    {/* <div className="grid grid-cols-2 gap-4 lg:w-1/3 mt-10">
                        <div className='border rounded-lg p-5 lg:w-74 bg-[#F4F6FC] '>
                            <FaNetworkWired className="w-10 h-10" />
                            <h1 className="text-xl uppercase font-bold my-5 text-[#e81c2e]">Network Solution</h1>
                            <p> The nerve center of your IT infrastructure is your network. So let us make sure your network is effective, secure, and accessible 24 hours a day.</p>
                        </div>
                        <div className='border rounded-lg p-5 w-74 bg-[#F4F6FC] origin-center'>
                            <img className="w-15 h-9" alt='' src={breakFix} />
                            <h1 className="text-xl uppercase font-bold my-5 text-[#e81c2e]">Break & Fix Services</h1>
                            <p>Canopus provides parts replacement services in Germany and at a few other international locations within 4 hours, 8 hours, and the following working day.</p>
                        </div>
                        <div className='border rounded-lg p-5 w-74 bg-[#F4F6FC] '>
                            <img className="w-15 h-9" alt='' src={cisco} /> 
                            <h1 className="text-xl uppercase font-bold my-5 text-[#e81c2e]">Cisco Support</h1>
                            <p>Our support staff is always prepared to assist you with their expert hands for any networking technology, including Cisco.</p>
                        </div>
                        <div className='border rounded-lg p-5 w-74 bg-[#F4F6FC] '>
                            <img className="w-15 h-9 bg-slate-900" alt='' src={dataCenter} />
                            <h1 className="text-xl uppercase font-bold my-5 text-[#e81c2e]">Data Center Maintenance</h1>
                            <p>Data center maintenance is extremely delicate and important work. We offer datacenter hardware deployment and round-the-clock maintenance assistance.</p>
                        </div>
                        <p className="hidden lg:block break-after-column absolute right-0 mt-56 origin-center rotate-90 text-6xl font-extrabold text-[#e81c2d23]">ACHIEVEMENTS</p>
                    </div> */}

                </div>

            </section>
            {/* services we provide */}
            <section className="bg-[#fcbcc2b0] py-20" id='service'>
                <h5 className="uppercase font-bold text-center text-primary">services we provide</h5>
                <h2 className="font-bold text-center text-5xl uppercase">Our Service Area</h2>
                <Services></Services>

            </section>

            <section className="py-20 bg-slate-100">
                <h5 className="uppercase font-bold text-center text-primary pt-10">vandors we supported</h5>
                <h2 className="font-bold text-center text-5xl uppercase pb-28">VENDORS SUPPORTEd</h2>
                <VandorSupport></VandorSupport>
                <div className="pb-28">

                </div>
            </section>
            <section>
                <GetaQuery></GetaQuery>
            </section>

        </div>

    );
};

export default Home;
