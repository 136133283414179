// import { signOut } from '@firebase/auth';
import React, { useState } from 'react';
// import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';
// import auth from '../../../firebase.init';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import logo from '../../../images/Group 2logo-1.png';

const Navbar = () => {

    const navigate = useNavigate();
    const [lastName, setLastName] = useState('');

    // const [user] = useAuthState(auth);

    const logout = () => {
        // signOut(auth);
        localStorage.removeItem('accessToken');
        navigate('/');

    };
    const profile = () => {
        navigate('/dashboard')
    };



    const menuItems = <>
        <li><Link to="/">Home</Link></li>
        <li><a href="#service">Services</a></li>
        {/* <li><a href="/blog">Blog</a></li> */}
        <li><a href="#aboutus">About Us</a></li>
        <li><a href="#contact">Contact Us</a></li>

        {/* <li>{user ? <div>
            <button className="btn btn-ghost" onClick={profile}>{user.displayName ? user.displayName?.split(' ')[user.displayName?.split(' ')?.length-1] : 'Profile'}</button>
            <button className="btn btn-ghost" onClick={logout}>Sign Out</button></div>
            :
            <Link to="/login">Login</Link>}</li> */}
    </>
    return (
        <div className="navbar bg-base-100 lg:justify-between sticky top-0 z-10">
            <div className="">
                <div className="dropdown">
                    <label tabIndex="0" className="btn btn-ghost lg:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                    </label>
                    <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 font-bold">
                        {menuItems}
                    </ul>
                </div>
                <Link to="/"><img alt="" className="w-60 ml-5 mt-5" src={logo} /> </Link>
            </div>

            <div className="navbar-center hidden lg:flex md:flex">
                <ul className="menu menu-horizontal p-0 font-bold text-2xl">
                    {menuItems}
                </ul>
            </div>
            <div className="menu menu-horizontal hidden lg:flex text-xl font-semibold">
                <li><a href="mailto:info@adiyaat.com"><FaEnvelope />info@adiyaat.com</a></li>
                <li><a href="tel:+447477194822"><FaPhone />+447477194822</a></li>
            </div>

        </div>
    );
};

export default Navbar;