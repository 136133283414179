import { Routes, Route } from 'react-router-dom';
import ContactMe from './Pages/ContactMe';
import Home from './Pages/Home/Home';
import Footer from './Pages/Shared/Footer/Footer';
import Navbar from './Pages/Shared/Navbar/Navbar';

function App() {
  return (
    <div className="w-full
    ">
      <Navbar  className=""></Navbar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/reqForService" element={<ContactMe />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
